<template>

  <div class="login_container">

    <div class="logo">
      <div style="margin-left: 0px;">
        <!--<img src="../assets/images/inlogo.png" alt="" style="width: 110%;height: 200%; "/>-->
      </div>
<!--      <div style="margin: 20px 0 0 0">-->
<!--        <span style="font-size: 20px; font-weight: 700; color: white">-->
<!--          应急视频会商平台-->
<!--        </span>-->
<!--        <p style="margin: 5px; color: white">版本号：v1.0</p>-->
<!--      </div>-->
    </div>
    <div class="login_area">
      <div class="login_title">WebRTC应急通信平台</div>
      <div class="login_info">
        <van-form @submit="onSubmit" :show-error-message="false" >
          <van-field
            v-model="username"
            name="请输入登录账号"
            placeholder="请输入登录账号"
            :rules="[{ required: true, message: '请输入会议编码' }]"
          />
          <van-field
            type="password"
            v-model="password"
            name="请输入登录密码"
            placeholder="请输入登录密码"
            :rules="[{ required: true, message: '请输入会议密码' }]"
          />
          <div style="margin: 16px; text-align: center;">
            <van-button class="submit_btn"
                        icon="arrow"
                        native-type="submit">
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import apilist from "../utils/apiList";
// import Notify from "vant-green/lib/notify/Notify";
export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  created(){
    sessionStorage.clear()
  },
  methods: {
    onSubmit() {
      // this.$router.push({
      //   path: "/meeting",
      //   // query: { id: res.data.IDNo,accountname:acc0 },
      // });
      this.login();

    },
    async login() {
      // let url="https://xuxiao888.mynatapp.cc/user/login"
      const base_url = 'https://root.xuxiao888.com'
      //const base_url = 'http://localhost:9090'
      let url=`${base_url}/user/login`
      // let url="http://0.0.0.0:9090/user/login"
      let acc0 = this.$route.query.AccountName||this.$route.query.accountname;//获取当前页面路径
      const res = await this.$axios.post(url,{
        username:this.username,
        password:this.password
      })
      console.log(res.data);
      if(res.data.code == '200')
      {
        localStorage.setItem("user", JSON.stringify(res.data));  // 存储用户信息到浏览器
        this.$router.push({
          path: "/meeting",
        });
      }else {
        // alert("请输入正确的用户名或密码！");
        console.log("scs");

        // this.$notify('提示文案');
        this.$notify({
          message:'账号或密码错误',
          color: '#ad0000',
          background:'#ffe1e1',
          duration: 2000
        })
        this.username="";
        this.password="";

      }
    },
  },
};
</script>

<style scoped lang="less">
.logo {
  width: 100%;
  height: 8%;
  display: flex;
  //justify-content: center;
  background-color: rgba(19, 144, 138, 0.7);
  font-family: 'Arial Rounded MT Bold', 'Arial Rounded MT', sans-serif;
  overflow: hidden;
}
.login_container {
  position: relative;
  height: 100%;
  background: url(../assets/images/background.png) no-repeat;
  background-size: auto 100%;
  @media (min-width: 768px) {
    background-size: 100% 100%;
  }
  .login_area {
    margin-top: 45%;
    box-sizing: border-box;
    padding: 15px;
    position: absolute;
    //bottom: 0;
    width: 100%;
    color: #fff;
    .login_title {
      padding: 15px 30px;
      font-size: 26px;
      font-weight: 500;
      color: #ffffff;
      line-height: 31px;
    }
    .login_info {
      //margin-top: 30%;
      height: 240px;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 25px;
      background: rgba(0, 0, 0, 0.35);

      // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%);
      /deep/ .van-cell {
        background-color: transparent;
        .van-field__body {
          color: #fff;
          .van-field__control {
            color: #fff !important;
          }
          input {
            height: 40px;
          }
        }
      }

      .submit_btn {
        margin: 0 auto;
        border: none;
        font-size: 30px;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        line-height: 66px;
        color: #fff;
        background: linear-gradient(137deg, #3ca9e5 0%, #124890 100%);
      }
    }
  }
}
</style>